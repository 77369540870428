@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100% /* white */;
        --foreground: 224 72% 5% /* gray-950 */;
        --card: 0 0% 100% /* white */;
        --card-foreground: 224 72% 5% /* gray-950 */;
        --popover: 0 0% 100% /* white */;
        --popover-foreground: 224 72% 5% /* gray-950 */;
        --primary: 244 76% 59% /* indigo-600 */;
        --primary-foreground: 226 100% 97% /* indigo-50 */;
        --secondary: 220 15% 96% /* gray-100 */;
        --secondary-foreground: 221 40% 11% /* gray-900 */;
        --muted: 220 15% 96% /* gray-100 */;
        --muted-foreground: 220 9% 47% /* gray-500 */;
        --accent: 220 15% 96% /* gray-100 */;
        --accent-foreground: 221 40% 11% /* gray-900 */;
        --destructive: 0 85% 61% /* red-500 */;
        --destructive-foreground: 210 20% 99% /* gray-50 */;
        --border: 220 14% 91% /* gray-200 */;
        --input: 220 14% 91% /* gray-200 */;
        --ring: 244 76% 59% /* indigo-600 */;
        --radius: 0.5rem;
    }

    .dark {
        --background: 224 72% 5% /* gray-950 */;
        --foreground: 210 20% 99% /* gray-50 */;
        --card: 224 72% 5% /* gray-950 */;
        --card-foreground: 210 20% 99% /* gray-50 */;
        --popover: 224 72% 5% /* gray-950 */;
        --popover-foreground: 210 20% 99% /* gray-50 */;
        --primary: 244 76% 59% /* indigo-600 */;
        --primary-foreground: 226 100% 97% /* indigo-50 */;
        --secondary: 215 28% 17% /* gray-800 */;
        --secondary-foreground: 210 20% 99% /* gray-50 */;
        --muted: 215 28% 17% /* gray-800 */;
        --muted-foreground: 218 11% 65% /* gray-400 */;
        --accent: 215 28% 17% /* gray-800 */;
        --accent-foreground: 210 20% 99% /* gray-50 */;
        --destructive: 0 63% 31% /* red-900 */;
        --destructive-foreground: 210 20% 99% /* gray-50 */;
        --border: 215 28% 17% /* gray-800 */;
        --input: 215 28% 17% /* gray-800 */;
        --ring: 244 76% 59% /* indigo-600 */;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
    }
}
